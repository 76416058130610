body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffffff;
}

#logo {
  width: 15vw;
  min-width: 200px;
}

#textContainer {
  position: absolute;
  bottom: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: center;
  font-weight: 500;
  color: #333;
}

a{
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

a:hover{
  color: #43A047;
}



